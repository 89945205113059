export interface AreaDeliveringReportCondition {
    startDate: Date | string;
    endDate: Date | string;
    areaId?: number;
}

export interface AreaDeliveringReport {
    areaId: number;
    areaName: string;
    fillingCount: { [key: number]: number };
    data: AreaDeliveringReport.CustomerTypeData[];
}

export namespace AreaDeliveringReport {
    export interface BottleTypeData {
        count: number;
        weight: number;
    }

    export interface CustomerTypeData {
        customerType: string;
        totalWeight: number;
        totalPrice: number;
        data: { [key: number]: BottleTypeData }
    }
}