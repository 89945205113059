import React from "react";
import { OrganizationIntroModel } from "../../models";
import { DataGrid } from "devextreme-react";
import { createGridOptions, remoteReadOnlyGridOptions, StdGrid } from "../../components";
import { IColumnProps } from "devextreme-react/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/data_grid";
import { organizationIntroStore } from "../../api";

export interface OrganizationIntroGridProps {
    onModelSelected(model: OrganizationIntroModel): void;
    onButtonClick(model: OrganizationIntroModel, name: string): void;
}

interface OrganizationIntroGridState {

}

export default class OrganizationIntroGrid
    extends React.Component<OrganizationIntroGridProps, OrganizationIntroGridState> {
    moduleName = "OrganizationIntro";
    gridRef = React.createRef<DataGrid>();
    gridOptions = createGridOptions("organizationIntros", this.gridRef, remoteReadOnlyGridOptions);

    columns: IColumnProps[] = [
        { dataField: 'code' },
        { dataField: 'name' },
        { 
            dataField: 'company',
            cellRender: (e: any) => {
                return <span className="link-button" onClick={()=>this.props.onButtonClick(e.data, "setlogo")}>
                    {e.data.company}
                </span>
            }
        },
        { dataField: 'createdAt' },
        { dataField: 'createdBy' },
        { dataField: 'updatedAt' }
    ];

    constructor(props: Readonly<OrganizationIntroGridProps>) {
        super(props);

        this.gridOptions.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.gridOptions.onRowDblClick = e => this.props.onButtonClick(e.data, "edit");
    }

    onSelectionChanged(e: SelectionChangedEvent) {
        if (e.selectedRowsData.length === 1) {
            this.props.onModelSelected(e.selectedRowsData[0]);
        } else {
            this.props.onModelSelected({});
        }
    }
    
    refresh() {
        this.gridRef.current?.instance.refresh();
    }

    render() {
        return (
            <StdGrid
                moduleName={this.moduleName}
                gridRef={this.gridRef}
                dataSource={organizationIntroStore}
                options={this.gridOptions}
                columns={this.columns}>
            </StdGrid>
        )
    }
}