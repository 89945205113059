import React from "react";
import AuthorizedComponent from "../authorized-component";
import OrganizationIntroForm from "./organization-intro-form";
import OrganizationIntroGrid from "./organization-intro-grid";
import { OrganizationIntroModel } from "../../models";
import { ContentBlock, ImagePopup, QrCodePopup, StdToolbar } from "../../components";
import { alertError, baseApiUrl, baseWxUrl, confirm2, enums, notifySuccess } from "../../base";
import { organizationIntroService } from "../../api";
import { Item } from "devextreme-react/toolbar";
import { Button } from "devextreme-react";

export default class OrganizationIntroPage extends AuthorizedComponent {
    objectName = "OrganizationIntro";
    gridRef = React.createRef<OrganizationIntroGrid>();
    formRef = React.createRef<OrganizationIntroForm>();
    toolbarRef = React.createRef<StdToolbar>();
    imagePopupRef = React.createRef<ImagePopup>();
    qrCodePopupRef = React.createRef<QrCodePopup>();

    constructor(props: Readonly<any>) {
        super(props);

        this.onModelSelected = this.onModelSelected.bind(this);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.onSaved = this.onSaved.bind(this);
        this.saveLogo = this.saveLogo.bind(this);
    }

    onModelSelected(model: OrganizationIntroModel) {
        this.toolbarRef.current?.setModel(model);
    }

    onButtonClick(model: OrganizationIntroModel, name: string) {
        switch (name) {
            case "edit":
                if (this.permissions[enums.stdActions.update]) {
                    this.formRef.current?.show(model, false);
                }
                break;

            case "add":
                if (this.permissions[enums.stdActions.create]) {
                    this.formRef.current?.show({}, true);
                }
                break;

            case "delete":
                if (this.permissions[enums.stdActions.delete]) {
                    this.delete(model);
                }
                break;

            case "setlogo":
                if (this.permissions[enums.stdActions.update]) {
                    this.setLogo(model);
                }
                break;

            case "showqrcode":
                this.showQrCode(model);
                break;
        }
    }

    onSaved() {
        this.gridRef.current &&
            this.gridRef.current.gridRef.current?.instance.refresh();
    }

    async delete(model: OrganizationIntroModel) {
        if (!await confirm2("确定要删除吗？")) {
            return;
        }

        const res = await organizationIntroService.delete(model.code!);

        if (res.succeeded) {
            this.gridRef.current?.refresh();
            notifySuccess("成功删除记录");
        } else {
            alertError(res.message!);
        }
    }

    async setLogo(model: OrganizationIntroModel) {
        const logo = model.company ? await organizationIntroService.getLogo(model.company) : undefined;

        this.imagePopupRef.current?.show(
            { model: model, logo: logo },
            logo ? [`${baseApiUrl}/api/file?name=${logo}`] : undefined);
    }

    async saveLogo(photo: string, stateObject: { model: OrganizationIntroModel, logo?: string }) {
        if (photo) {
            const result = await organizationIntroService.setLogo(stateObject.model.company!, photo);

            if (result.succeeded) {
                notifySuccess("已成功上传LOGO");
                this.imagePopupRef.current?.hide();
            } else {
                alertError(result.message!);
            }
        } else if (!photo && stateObject.logo) {
            const result = await organizationIntroService.deleteLog(stateObject.model.company!);

            if (result.succeeded) {
                notifySuccess("已成功删除LOGO");
                this.imagePopupRef.current?.hide();
            } else {
                alertError(result.message!);
            }
        }
    }

    showQrCode(model: OrganizationIntroModel) {
        this.qrCodePopupRef.current?.show(`${baseWxUrl}/site/${model.code}`);
    }

    render() {
        return (
            <ContentBlock caption={"站点简介"}>
                <StdToolbar
                    permissions={this.permissions}
                    ref={this.toolbarRef}
                    primaryKey="code"
                    itemsAfter={(model) => (
                        <Item location={"after"} locateInMenu="auto">
                            <Button
                                disabled={!model['code']}
                                text="显示二维码"
                                onClick={() => this.onButtonClick(model, "showqrcode")} />
                        </Item>
                    )}
                    onButtonClick={this.onButtonClick} />
                <OrganizationIntroGrid
                    ref={this.gridRef}
                    onModelSelected={this.onModelSelected}
                    onButtonClick={this.onButtonClick} />
                <OrganizationIntroForm ref={this.formRef} onSaved={this.onSaved} />
                <ImagePopup ref={this.imagePopupRef} maxPhotos={1} enableEmpty={true} onSaving={this.saveLogo} />
                <QrCodePopup ref={this.qrCodePopupRef} />
            </ContentBlock>
        );
    }
}