import { createStoreFromUrl, BaseApiService, http, DataBool, MessageBool } from "../base";
import { OrganizationIntroModel } from "../models";

class OrganizationIntroService extends BaseApiService<OrganizationIntroModel> {
    moduleName = "organizationintro";

    getDxData() {
        return createStoreFromUrl(`${this.apiPath}/dx`, 'code');
    }

    getLogo(companyName: string): Promise<string|null|undefined> {
        return http.getData(`${this.apiPath}/logo?companyName=${companyName}`);
    }

    setLogo(companyName: string, logoPath: string): Promise<DataBool<string>> {
        return http.putData(`${this.apiPath}/logo`, { companyName: companyName, logoPath: logoPath });
    }

    deleteLog(companyName: string): Promise<MessageBool> {
        return http.deleteData(`${this.apiPath}/logo?companyName=${companyName}`);
    }
}

export const organizationIntroService = new OrganizationIntroService();
export const organizationIntroStore = organizationIntroService.getDxData();