export interface NavigationItem {
    id?: string;
    text: string;
    path?: string;
    icon?: string;
    objectName?: string;
    actionName?: string;

    items?: NavigationItem[];
}

export const homeItem: NavigationItem = {
    text: '主页',
    path: '/home',
    icon: 'bi bi-house'
};

export const favoriteItem: NavigationItem = {
    text: '收藏夹',
    icon: 'bi bi-star',
    items: []
};

export const navigation: NavigationItem[] = [
    {
        text: '客户管理',
        icon: 'bi bi-people',
        items: [
            {
                text: '客户管理',
                path: '/customer',
                objectName: 'Customer'
            },
            {
                text: '微信开户申请',
                path: '/customer-request',
                objectName: 'CustomerRequest'
            },
            {
                text: '退瓶还瓶单',
                path: '/recycle-request',
                objectName: 'RecycleRequest'
            },
            {
                text: '客户回访',
                path: '/customer-callback',
                objectName: 'CustomerCallback'
            },
            {
                text: '客户评价',
                path: '/order-evaluation',
                objectName: 'OrderEvaluation'
            },
            {
                text: '客户投诉',
                path: '/customer-complain',
                objectName: 'CustomerComplain'
            },
            {
                text: '异常数据预警',
                path: '/report/exception-warning',
                objectName: 'ExceptionData',
                actionName: 'Warning'
            }
        ]
    },
    {
        text: '订单管理',
        icon: 'bi bi-file-earmark-text',
        items: [
            {
                text: '订单管理',
                path: '/order',
                objectName: 'Order'
            },
            {
                text: '欠瓶押瓶',
                path: '/debt',
                objectName: 'BottleDebt'
            },
            {
                text: '钢瓶使用费管理',
                path: '/rent',
                objectName: 'BottleRent'
            },
            {
                text: '配件订单',
                path: '/product-order',
                objectName: 'ProductOrder'
            },
            {
                text: '退瓶还瓶记录',
                path: '/bottle-recycle-log',
                objectName: 'BottleRecycleLog'
            },
            {
                text: '订单预测',
                path: '/report/order-forecast',
                objectName: 'Order',
                actionName: 'Forecast'
            }
        ]
    },
    {
        text: '安全管理',
        icon: 'bi bi-shield-check',
        items: [
            {
                text: '安全检查',
                path: '/security-check',
                objectName: 'SecurityCheck'
            },
            {
                text: '整改通知',
                path: '/danger-notification',
                objectName: 'Danger'
            },
            {
                text: '隐患整改',
                path: '/danger',
                objectName: 'Danger'
            },
            {
                text: '抢修管理',
                path: '/troubleshooting',
                objectName: 'Troubleshooting'
            },
            {
                text: '站点安全检查',
                path: '/site-check',
                objectName: 'SiteCheck'
            },
            {
                text: '站点隐患整改',
                path: '/site-danger',
                objectName: 'SiteDanger'
            },
            {
                text: '安全会议培训',
                path: '/training',
                objectName: 'Training'
            }
        ]
    },
    {
        text: '气瓶管理',
        icon: 'gas-bottle',
        items: [
            {
                text: '气瓶流转',
                path: '/bottle-flow',
                objectName: 'BottleFlow'
            },
            {
                text: '气瓶位置',
                path: '/bottle-position',
                objectName: 'BottlePosition'
            },
            {
                text: '气瓶档案',
                path: '/bottle',
                objectName: 'Bottle'
            },
            {
                text: '充装档案',
                path: '/filling',
                objectName: 'BottleFilling'
            },
            {
                text: '充前检查',
                path: '/filling-precheck',
                objectName: 'FillingPrecheck'
            },
            {
                text: '充后检查',
                path: '/filling-recheck',
                objectName: 'FillingRecheck'
            },
            {
                text: '充装设置',
                path: '/filling-setting',
                objectName: 'FillingSetting'
            },
            {
                text: '车辆档案',
                path: '/vehicle',
                objectName: 'Vehicle'
            },
            {
                text: '车辆人员档案',
                path: '/vehicle-user',
                objectName: 'VehicleUser'
            },
            {
                text: '添加运输记录',
                path: '/new-shipping',
                objectName: 'Shipping',
                actionName: 'Create'
            },
            {
                text: '运输记录',
                path: '/shipping-log',
                objectName: 'Shipping'
            }
        ]
    },
    {
        text: '库存管理',
        icon: 'bi bi-shop',
        items: [
            {
                text: '库存盘点',
                path: '/stocktaking',
                objectName: 'GasStocktaking'
            },
            {
                text: '库存报表',
                path: '/report/stocktaking',
                objectName: 'GasStocktaking'
            },
            {
                text: '库存初量设置',
                path: '/stock-init',
                objectName: 'StockInit'
            }
        ]
    },
    {
        text: '信息公告',
        icon: 'bi bi-megaphone',
        items: [
            {
                text: '公告管理',
                path: '/announcement',
                objectName: 'Announcement'
            },
            {
                text: '公告列表',
                path: '/my-announcement'
            },
            {
                text: '消息提醒',
                path: '/web-message'
            },
            // {
            //     text: '短信群发',
            //     path: '/sms',
            //     objectName: 'Sms'
            // }
        ]
    },
    {
        text: '组织人员',
        icon: 'bi bi-person',
        items: [
            {
                text: '组织架构',
                path: '/organization',
                objectName: 'Organization'
            },
            {
                text: '员工管理',
                path: '/employee',
                objectName: 'Employee'
            },
            {
                text: '角色管理',
                path: '/role',
                objectName: 'Role'
            },
            {
                text: '定位跟踪',
                path: '/employee-position',
                objectName: 'EmployeePosition'
            },
            {
                text: '站点简介',
                path: '/organization-intro',
                objectName: 'OrganizationIntro'
            }
        ]
    },
    {
        text: '报表查询',
        icon: 'bi bi-bar-chart',
        items: [
            {
                text: '销售汇总',
                path: '/report/order-summary',
                objectName: 'Order',
                actionName: 'SaleReport'
            },
            {
                text: '客户在用气瓶',
                path: '/report/customer-bottle',
                objectName: 'Customer',
                actionName: 'BottleReport'
            },
            {
                text: '气瓶周转率',
                path: '/report/bottle-turnover',
                objectName: 'BottleFlow',
                actionName: 'TurnoverReport'
            },
            {
                text: '送气工绩效统计',
                path: '/report/deliver-performance',
                objectName: 'Order',
                actionName: 'DeliverPerformanceReport'
            },
            {
                text: '充装与配送统计',
                path: '/report/filling-delivering',
                objectName: 'Organization',
                actionName: 'FillingDeliveringReport'
            },
            {
                text: '地区配送统计',
                path: '/report/area-delivering',
                objectName: 'Order',
                actionName: 'AreaDeliveringReport'
            },
            {
                text: '活跃客户及配送数量',
                path: '/report/active-customer',
                objectName: 'Customer',
                actionName: 'ActiveReport'
            },
            {
                text: '平均用气量',
                path: '/report/average-usage',
                objectName: 'Order',
                actionName: 'AverageUsageReport'
            },
            {
                text: '客户增减统计',
                path: '/report/customer-trend',
                objectName: 'Customer',
                actionName: 'TrendReport'
            },
            {
                text: '订单趋势',
                path: '/report/order-trend',
                objectName: 'Order',
                actionName: 'TrendReport'
            },
            {
                text: 'APP在线情况',
                path: '/employee-app-status',
                objectName: 'EmployeeAppStatus'
            },
            {
                text: '流转异常日志',
                path: '/report/exception-summary',
                objectName: 'ExceptionData',
                actionName: 'Report'
            },
            {
                text: '操作日志',
                path: '/operation-log',
                objectName: 'OperationLog'
            }
        ]
    },
    {
        text: '系统管理',
        icon: 'bi bi-gear',
        items: [
            {
                text: '安检项设置',
                path: '/security-check-type',
                objectName: 'SecurityCheckType'
            },
            {
                text: '随单安检项设置',
                path: '/order-check-type',
                objectName: 'OrderCheckType'
            },
            {
                text: '站点安检项设置',
                path: '/site-check-type',
                objectName: 'SiteCheckType'
            },
            {
                text: '充装检查项目',
                path: '/filling-check-type',
                objectName: 'FillingCheckType'
            },
            {
                text: '下拉数据定义',
                path: '/data-source',
                objectName: 'DataSource'
            },
            {
                text: '预定义标签',
                path: '/predefined-label',
                objectName: 'PredefinedLabel'
            },
            {
                text: '异常规则设置',
                path: '/exception-setting',
                objectName: 'ExceptionSetting'
            },
            {
                text: '客户类型',
                path: '/customer-type',
                objectName: 'CustomerType'
            },
            {
                text: '产品管理',
                path: '/product',
                objectName: 'Product'
            },
            {
                text: '钢瓶厂家',
                path: '/bottle-vendor',
                objectName: 'BottleVendor'
            },
            {
                text: '充装员工',
                path: '/filling-user',
                objectName: 'FillingUser'
            },
            {
                text: '充装去向',
                path: '/filling-target',
                objectName: 'FillingTarget'
            },
            {
                text: '地区',
                path: '/area',
                objectName: 'Area'
            },
            {
                text: '微信配置',
                path: '/wxconfig',
                objectName: 'WxConfig'
            },
            {
                text: '系统日志',
                path: '/sys-log',
                objectName: 'SysLog'
            },
            {
                text: 'BUG跟踪',
                path: '/bug-trace',
                objectName: 'BugTrace'
            }
        ]
    },
    {
        text: "条码打印",
        icon: 'bi bi-upc',
        items: [
            {
                text: '批次管理',
                path: '/cp-batch',
                objectName: 'CpBatch'
            },
            {
                text: '新瓶管理',
                path: '/cp-new',
                objectName: 'CpNew'
            },
            {
                text: '送检管理',
                path: '/cp-check',
                objectName: 'CpCheck'
            },
            {
                text: '检回管理',
                path: '/cp-check-back',
                objectName: 'CpCheckBack'
            },
            {
                text: '报废瓶管理',
                path: '/cp-scrap',
                objectName: 'CpScrap'
            },
            {
                text: '气瓶型号',
                path: '/cp-bottle-type',
                objectName: 'CpDataSource'
            },
            {
                text: '厂家档案',
                path: '/cp-vendor',
                objectName: 'CpDataSource'
            },
            {
                text: '站点档案',
                path: '/cp-organization',
                objectName: 'CpDataSource'
            },
            {
                text: '检验单位',
                path: '/cp-checker',
                objectName: 'CpDataSource'
            },
            {
                text: '打印日志',
                path: '/cp-log',
                objectName: 'CpLog'
            }
        ]
    }
];
