import OrganizationPage from "../pages/organization/organization";
import RolePage from "../pages/organization/role";
import EmployeePage from "../pages/organization/employee";
import { RouteConfig } from "./route-model";
import EmployeePositionPage from "../pages/organization/employee-position";
import OrganizationIntroPage from "../pages/organization/organization-intro";

export const orgRoutes: RouteConfig[] = [
    {
        path: '/organization',
        component: OrganizationPage
    },
    {
        path: '/role',
        component: RolePage
    },
    {
        path: '/employee',
        component: EmployeePage
    },
    {
        path: "/employee-position",
        component: EmployeePositionPage
    },
    {
        path: "/organization-intro",
        component: OrganizationIntroPage
    }
];