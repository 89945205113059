import { PagingList, User } from "./models";

export const logoText = "集顺燃气";
export const corpName = "厦门集顺石油液化气有限公司";

export const baseApiUrl =
    window.location.host.match(/^localhost/i) ? 'http://localhost:6001' : '';
export const baseWxUrl = 
    window.location.host.match(/^localhost/i) ? 'http://192.168.3.120:6003' : 'https://wxm.xmjs.com.cn:8090';

export const temporaryUploadUrl = "/api/temporary";

export const stdSeparator = "，";

export const stdDateFormat = "yyyy/MM/dd";
export const stdTimeFormat = "yyyy/MM/dd HH:mm:ss";
export const stdTimeOnlyFormat = "HH:mm:ss";

export const phoneRegex = /^(1\d{10}|(0592)?-?\d{7})$/;

export function now() {
    return new Date();
};

export function today() {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
};

export function dateAdd(date: Date, value: number, type: string = "d") {
    switch (type?.toLowerCase()) {
        case "y":
            return new Date(date.getFullYear() + value, date.getMonth(), date.getDate());
        case "m":
            return new Date(date.getFullYear(), date.getMonth() + value, date.getDate());
        case "d":
        default:
            return new Date(date.getFullYear(), date.getMonth(), date.getDate() + value);
    }
}

export function timeAdd(date: Date, value: number, type: string = "d") {
    switch (type?.toLowerCase()) {
        case "y":
            return new Date(date.getFullYear() + value, date.getMonth(), date.getDate(),
                date.getHours(), date.getMinutes(), date.getSeconds());
        case "m":
            return new Date(date.getFullYear(), date.getMonth() + value, date.getDate(),
                date.getHours(), date.getMinutes(), date.getSeconds());
        case "h":
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                date.getHours() + value, date.getMinutes(), date.getSeconds());
        case "n":
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                date.getHours(), date.getMinutes() + value, date.getSeconds());
        case "s":
            return new Date(date.getFullYear(), date.getMonth(), date.getDate(),
                date.getHours(), date.getMinutes(), date.getSeconds() + value);
        case "d":
        default:
            return new Date(date.getFullYear(), date.getMonth(), date.getDate() + value,
                date.getHours(), date.getMinutes(), date.getSeconds());
    }
}

export const emptyList: PagingList = {
    pagedDataSource: [],
    pageCount: 0,
    pageIndex: 0,
    pageSize: 20,
    recordCount: 0,
    summaryData: {}
};

export const anonymousUser: User = {
    id: 'anonymous',
    name: '未登录',
    isAdmin: false,
    mocking: false,
    organizationId: 0,
    claims: []
};