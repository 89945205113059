import { Popup } from "devextreme-react";
import React from "react";
import { alertWarning, baseApiUrl, temporaryUploadUrl } from "../../base";
import { MultiImageUploader } from "../file";
import { PopupContentBox } from "../layouts";
import RefPopup from "../popup/ref-popup";

export interface ImagePopupProps {
    maxPhotos?: number;
    enableEmpty?: boolean;
    onSaving: (photo: string, stateObject: any) => void;
}

interface ImagePopupState {
    visible: boolean;
}

export default class ImagePopup extends React.Component<ImagePopupProps, ImagePopupState> {
    popupRef = React.createRef<Popup>();
    sources: any[] = [];
    photo: string = "";
    stateObject: any;

    constructor(props: Readonly<ImagePopupProps>) {
        super(props);

        this.state = {
            visible: false,
        }

        this.onUploaded = this.onUploaded.bind(this);
        this.onRemoved = this.onRemoved.bind(this);
        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async show(stateObject?: any, sources?: string[]) {
        await this.popupRef.current?.instance.show();
        this.sources = sources || [];
        this.photo = "";
        this.stateObject = stateObject;
        this.setState({ visible: true });
    }

    async hide() {
        await this.popupRef.current?.instance.hide();
        this.setState({ visible: false })
    }

    onUploaded(tmpName: string) {
        this.photo = this.photo ? this.photo + ";" + tmpName : tmpName;
    }

    onRemoved(index: number) {
        const arr = (this.photo || "").split(';');
        arr.splice(index, 1);
        this.photo = arr.join(";");
    }

    async save() {
        if (!this.photo && !this.props.enableEmpty) {
            alertWarning("请选择或拖动图片上传");
            return;
        }

        this.props.onSaving(this.photo, this.stateObject);
    }

    cancel() {
        this.hide();
    }

    render() {
        const url = `${baseApiUrl}${temporaryUploadUrl}`;
        
        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"上传图片"}
                width={400}
                height={400}>
                <PopupContentBox onSave={this.save} onCancel={this.cancel}>
                    {this.state.visible && (
                        <MultiImageUploader 
                            id={"popupImageUploader"} 
                            uploadUrl={url} 
                            sources={this.sources} 
                            maxPhotos={this.props.maxPhotos}
                            onUploaded={this.onUploaded}
                            onRemoved={this.onRemoved} />
                    )}
                </PopupContentBox>
            </RefPopup>
        )
    }
}