import "./multi-image-uploader.scss";
import React from "react";
import { FileUploader, ProgressBar, Tooltip } from "devextreme-react";
import { DropZoneEnterEvent, DropZoneLeaveEvent, UploadedEvent, ProgressEvent, UploadErrorEvent } from "devextreme/ui/file_uploader";
import { alertError, confirm2, tokenStorage } from "../../base";

export interface MultiImageUploaderProps {
    id: string;
    uploadUrl: string;
    sources: any[];
    maxPhotos?: number;
    onUploaded?: (tmpName: string, source: any) => void;
    onRemoved?: (index: number, source: any) => void;
}

interface MultiImageUploaderState {
    isDropZoneActive: boolean;
    sources: any[];
    popoverToggles: boolean[];
    progressVisible: boolean;
    progressValue: number
}

export default class MultiImageUploader extends React.Component<MultiImageUploaderProps, MultiImageUploaderState> {
    allowedFileExtension = ['.jpg', '.jpeg', '.gif', '.png'];

    constructor(props: Readonly<MultiImageUploaderProps>) {
        super(props);

        this.state = {
            isDropZoneActive: false,
            sources: props.sources,
            popoverToggles: props.sources.map(s => false),
            progressVisible: false,
            progressValue: 0
        };

        this.onDropZoneEnter = this.onDropZoneEnter.bind(this);
        this.onDropZoneLeave = this.onDropZoneLeave.bind(this);
        this.onUploaded = this.onUploaded.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this.onUploadStarted = this.onUploadStarted.bind(this);
    }

    switchToggle(index: number, value: boolean) {
        const toggles = [...this.state.popoverToggles.map(t => false)];
        toggles[index] = value;
        this.setState({ popoverToggles: toggles });
    }

    onDropZoneEnter(e: DropZoneEnterEvent) {
        if (e.dropZoneElement.id === this.props.id) {
            this.setState({ isDropZoneActive: true });
        }
    }

    onDropZoneLeave(e: DropZoneLeaveEvent) {
        if (e.dropZoneElement.id === this.props.id) {
            this.setState({ isDropZoneActive: false });
        }
    }

    onUploaded(e: UploadedEvent) {
        const file = e.file;
        const fileReader = new FileReader();

        fileReader.onload = () => {
            this.setState({
                isDropZoneActive: false,
                sources: [...this.state.sources, fileReader.result]
            });

            this.props.onUploaded &&
                this.props.onUploaded(e.request.responseText!, fileReader.result);
        }

        fileReader.readAsDataURL(file);

        this.setState({
            progressVisible: false,
            progressValue: 0
        });
    }

    onProgress(e: ProgressEvent) {
        this.setState({ progressValue: e.bytesLoaded / e.bytesTotal * 100 })
    }

    onUploadStarted() {
        this.setState({
            progressVisible: true,
        })
    }

    onUploadError(e: UploadErrorEvent) {
        console.log(e);
        alertError("无法上传文件，请稍后再试，如仍无法上传，请与管理员联系！");
    }

    async remove(index: number) {
        if (await confirm2("确定要删除该图片吗？")) {
            const removed = this.state.sources.splice(index, 1);
            this.setState({ sources: this.state.sources });

            this.props.onRemoved &&
                this.props.onRemoved!(index, removed[0]);
        }
    }

    render() {
        const { id, uploadUrl, maxPhotos } = this.props;
        const { isDropZoneActive, sources, popoverToggles, progressVisible, progressValue } = this.state;

        const externalCss = isDropZoneActive ? 'dropzone dropzone-active' : 'dropzone';
        const header = { Authorization: `bearer ${tokenStorage.token}` };

        return (
            <div className="image-uploader multi-image-uploader">
                {
                    sources.map((s, index) => (
                        <React.Fragment key={index}>
                            <div
                                id={`${id}_${index}`}
                                className="image-block flex-box"
                                onMouseEnter={() => this.switchToggle(index, true)}
                                onMouseLeave={() => this.switchToggle(index, false)}>
                                <img src={s} alt="" />
                                <span className="image-block-close">
                                    <i className="bi bi-x-square-fill" onClick={() => this.remove(index)}></i>
                                </span>
                            </div>
                            <Tooltip
                                target={`#${id}_${index}`}
                                maxWidth={500}
                                maxHeight={500}
                                showTitle={false}
                                className="image-popover"
                                visible={popoverToggles[index]}>
                                <div className="pimage-container">
                                    <img src={s} alt="" />
                                </div>
                            </Tooltip>
                        </React.Fragment>
                    ))
                }
                <div id={id} className={`image-block flex-box ${externalCss}`} style={{ display: (!maxPhotos || sources.length < maxPhotos) ? "" : "none" }}>
                    <div>
                        <i className="bi bi-plus"></i>
                    </div>
                    <ProgressBar
                        min={0}
                        max={100}
                        width="70%"
                        showStatus={false}
                        visible={progressVisible}
                        value={progressValue} />
                </div>
                <FileUploader
                    dialogTrigger={`#${id}`}
                    dropZone={`#${id}`}
                    multiple={false}
                    allowedFileExtensions={this.allowedFileExtension}
                    uploadMode="instantly"
                    uploadUrl={uploadUrl}
                    uploadHeaders={header}
                    visible={false}
                    name={"file"}
                    onDropZoneEnter={this.onDropZoneEnter}
                    onDropZoneLeave={this.onDropZoneLeave}
                    onUploaded={this.onUploaded}
                    onProgress={this.onProgress}
                    onUploadStarted={this.onUploadStarted}
                    onUploadError={this.onUploadError} />
            </div>
        )
    }
}