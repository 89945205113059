import React from "react";
import { Popup } from "devextreme-react";
import { RefPopup } from "../popup";
import { baseApiUrl } from "../../base";

interface QrCodePopupState {
    qrCode?: string;
}

export default class QrCodePoup extends React.Component<any, QrCodePopupState> {
    popupRef = React.createRef<Popup>();
    url = `${baseApiUrl}/api/file/qrcode`;

    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            qrCode: undefined
        };

        this.hide = this.hide.bind(this);
    }

    show(qrCode: string) {
        this.popupRef.current?.instance.show();

        this.setState({ qrCode: qrCode });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    render() {
        const { qrCode } = this.state;

        return (
            <RefPopup
                popupRef={this.popupRef}
                title={"二维"}
                width={500}
                height={550}
                showCloseButton={true}>
                <div className={"flex-box"}>
                    {qrCode && (
                        <img src={`${this.url}?code=${qrCode}`} alt="" />
                    )} 
                </div>
            </RefPopup>
        );
    }
}