import React from "react";
import { OrganizationIntroModel } from "../../models";
import { Form, Popup } from "devextreme-react";
import { IItemProps } from "devextreme-react/form";
import { alertError, modelClone } from "../../base";
import { organizationIntroService } from "../../api";
import { PopupContentBox, RefPopup, StdForm } from "../../components";

export interface OrganizationIntroFormProps {
    onSaved: () => void;
}

interface OrganizationIntroFormState {
    model: OrganizationIntroModel;
    isNew: boolean;
}

export default class OrganizationIntroForm
    extends React.Component<OrganizationIntroFormProps, OrganizationIntroFormState> {
    moduleName = "OrganizationIntro";

    popupRef = React.createRef<Popup>();
    formRef = React.createRef<Form>();

    items: IItemProps[] = [
        { dataField: 'code' },
        { dataField: 'name' },
        { dataField: 'company' },
        { 
            dataField: 'description', 
            editorType: 'dxTextArea', 
            editorOptions: { height: "300px" } 
        }
    ];

    constructor(props: Readonly<OrganizationIntroFormProps>) {
        super(props);

        this.state = {
            model: {},
            isNew: true
        };

        this.save = this.save.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    show(model: OrganizationIntroModel, isNew: boolean) {
        this.popupRef.current?.instance.show();

        this.setState({ model: modelClone(model), isNew: isNew });
    }

    hide() {
        this.popupRef.current?.instance.hide();
    }

    async save() {
        const isValid = this.formRef.current?.instance.validate();

        if (isValid?.isValid) {
            const model = this.state.model;
            const res = this.state.isNew ?
                await organizationIntroService.create(model) :
                await organizationIntroService.update(model);

            if (res.succeeded) {
                this.hide();
                this.props.onSaved();
            } else {
                alertError(res.message!);
            }
        }
    }

    cancel() {
        this.hide();
    }

    render() {
        return (
            <RefPopup
                popupRef={this.popupRef}
                title="编辑"
                width={800}
                height={600}>
                <PopupContentBox scrollable={true} onSave={this.save} onCancel={this.cancel}>
                    <StdForm
                        moduleName={this.moduleName}
                        formRef={this.formRef}
                        items={this.items}
                        formData={this.state.model} />
                </PopupContentBox>
            </RefPopup>
        )
    }
}